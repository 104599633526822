import { Box } from '@mui/material';
import { GoogleLogin } from '@react-oauth/google';
import scrybLogo from 'assets/img/scryb_logo_lg_blue.png';
import { useAuth } from 'contexts/auth-context';
import { useEffect } from 'react';

const SignIn = () => {
  const { signIn, user, navigateFromSignIn } = useAuth();

  useEffect(() => {
    if (user?.email && user?.token) {
      return navigateFromSignIn();
    }
  }, [user, navigateFromSignIn]);

  const onSuccess = async (credentialResponse) => {
    return signIn(credentialResponse.credential);
  };

  const onError = (errorResponse) => console.log('SignIn err', errorResponse);

  return (
    <Box
      sx={{
        width: '100%',
        height: '75svh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <img
        src={scrybLogo}
        alt="scryb logo"
        style={{ width: '30rem', margin: '1rem', marginBottom: '3rem' }}
      />
      <GoogleLogin
        onSuccess={onSuccess}
        onError={onError}
        size={'large'}
        shape={'rectangular'}
        useOneTap
      />
    </Box>
  );
};

export default SignIn;
