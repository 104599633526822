import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { PRODUCT_TYPES } from 'lib/constants/compliance';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { jiraService } from 'services/jira.service';
import { riskService } from 'services/risk.service';
import './compliance-risk-index.css';

const getRiskScoreClass = (params) => {
  const score = params.value;

  if (score >= 1 && score <= 23) {
    return 'risk-level-low';
  } else if (score >= 24 && score <= 35) {
    return 'risk-level-med';
  } else if (score >= 36 && score <= 125) {
    return 'risk-level-high';
  }

  return '';
};

const getNumericColClass = (params) => {
  const prob = params.value;
  if (prob >= 1 && prob <= 6) return `risk-level-${prob}`;
  if (prob > 6) return 'risk-level-high';
  return '';
};

const columns = [
  { field: 'HA Number' },
  {
    field: 'Text for HA ref selection',
    minWidth: 150,
    flex: 1
  },
  {
    field: 'Reference',
    minWidth: 100
  },
  {
    field: 'Type of hazard',
    minWidth: 150
  },
  {
    field: 'Hazard categorization',
    minWidth: 100,
    flex: 1
  },
  {
    field: 'Hazardous Event or Situation',
    minWidth: 100,
    flex: 1
  },
  {
    field: 'Risk Score',
    flex: 0,
    cellClassName: getRiskScoreClass
  },
  {
    field: 'Probability',
    flex: 0,
    cellClassName: getNumericColClass
  },
  {
    field: 'Severity',
    flex: 0,
    cellClassName: getNumericColClass
  },
  {
    field: 'Detectability',
    flex: 0,
    cellClassName: getNumericColClass
  }
];

const ComplianceRiskIndex = () => {
  const [searchParams] = useSearchParams();
  const prId = searchParams.get('prId');
  const prType = searchParams.get('prType');

  const { data: risksInternal = [], isPending: isPendingInternal } = useQuery({
    queryKey: ['risksData', prId],
    queryFn: () => riskService.getAppRequirementsDataSection(prId),
    enabled: !!prId && prType === PRODUCT_TYPES.INTERNAL
  });

  const { data: risksJira = [], isPending: isPendingJira } = useQuery({
    queryKey: ['risksJia', prId],
    queryFn: () => jiraService.getAllRisksByProduct(prId),
    enabled: !!prId && prType === PRODUCT_TYPES.JIRA
  });

  const risks = useMemo(() => {
    if (prType === PRODUCT_TYPES.INTERNAL) return risksInternal;
    if (prType === PRODUCT_TYPES.JIRA) return risksJira;
    return [];
  }, [risksInternal, risksJira, prType]);

  const isPending = useMemo(() => {
    if (prType === PRODUCT_TYPES.INTERNAL) return isPendingInternal;
    if (prType === PRODUCT_TYPES.JIRA) return isPendingJira;
    return false;
  }, [isPendingInternal, isPendingJira, prType]);

  return (
    <div style={{ width: '100%', paddingTop: '0.5rem', paddingBottom: '1rem' }}>
      <DataGrid
        getRowId={(row) => row._id}
        rows={risks}
        columns={columns}
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } }
        }}
        disableSelectionOnClick
        disableMultipleRowSelection
        disableDensitySelector
        disableColumnSelector
        pageSizeOptions={[5, 10, 25, 50, { label: 'All', value: -1 }]}
        getRowHeight={() => 'auto'}
        loading={isPending}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true
          }
        }}
      />
    </div>
  );
};

export default ComplianceRiskIndex;
