import { Button as BaseButton } from '@mui/base/Button';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/system';

const SignOutContainer = ({ onClick, srcImg }) => {

  return (
    <Stack spacing={2} direction="row">
      <Divider orientation="vertical" flexItem style={{ paddingLeft: '1rem' }} />
      <Avatar alt="Tracebox User" src={srcImg} />
      <Button onClick={onClick}>SIGN OUT</Button>
    </Stack>
  );
};

const blue = {
  200: '#a3c8ed',
  300: '#75ace4',
  400: '#4691db',
  500: '#1976d2',
  600: '#145ea8',
  700: '#0f467e'
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025'
};

const Button = styled(BaseButton)(
  ({ theme }) => `
  min-width: 7rem;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.5;
  background-color: ${blue[500]};
  border-radius: 8px;
  color: white;
  transition: all 150ms ease;
  cursor: pointer;
  border: 1px solid ${grey[200]};

  &:hover {
    background-color: ${blue[600]};
  }

  &:active {
    background-color: ${blue[700]};
    box-shadow: none;
    transform: scale(0.99);
  }

  &:focus-visible {
    outline: none;
  }

  &.base--disabled {
    background-color: ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    color: ${grey[700]};
    border: 0;
    cursor: default;
    box-shadow: none;
    transform: scale(1);
  }
`
);

export default SignOutContainer;
