const NIST_ACTION_TYPES = ['govern', 'map', 'measure', 'manage'];

const NIST_ACTION_STATUS_COLORS = ['primary', 'secondary', 'success'];
const NIST_ACTION_STATUS_COLORS_HEX = ['#1976d2', '#9c27b0', '#2e7d32'];
const NIST_ACTION_STATUS_COLOR_9_HEX = [
  ['#42a5f5', '#1976d2', '#1565c0'],
  ['#ba68c8', '#9c27b0', '#7b1fa2'],
  ['#4caf50', '#2e7d32', '#1b5e20']
];
const NIST_ACTION_STATUSES = ['To Do', 'In Progress', 'Completed'];

const COMPLIANCE_TABS = [
  { name: 'DASHBOARD', route: 'dashboard' },
  { name: 'USERS', route: 'users' },
  { name: 'PRODUCTS', route: 'products' },
  { name: 'ACTIONS', route: 'actions' },
  { name: 'DOCUMENTS', route: 'documents' },
  { name: 'RISK', route: 'risk' },
  { name: 'NIST', route: 'nist' }
];

const COMPLIANCE_DYNAMIC_TABS = [
  { name: 'Canada', route: 'canada' },
  { name: 'EU', route: 'eu' },
  { name: 'UK', route: 'uk' },
  { name: 'CUSTOM POLICIES', route: 'custom-policies' }
];

// Directly reference the actual icon components instead of their string names.
const VERTICAL_TABS = [
  { name: 'STATUS SUMMARY', route: 'status-summary', icon: 'dashboard' },
  { name: 'PRODUCT TYPES', route: 'product-types', icon: 'products' },
  { name: 'TOP ACTIONS', route: 'top-actions', icon: 'actions' },
  { name: 'TOP USERS', route: 'top-users', icon: 'users' }
];

const NIST_COLOR_MAP = {
  map: '#42a5f5',
  govern: '#1976D2',
  measure: '#0288d1',
  manage: '#01579b'
};

const PRODUCT_TYPES = {
  THIRD_PARTY: 'thirdParty',
  INTERNAL: 'internal',
  JIRA: 'jira'
};

const RISK_PRODUCT_TYPES = [PRODUCT_TYPES.INTERNAL, PRODUCT_TYPES.JIRA];

export {
  COMPLIANCE_DYNAMIC_TABS,
  COMPLIANCE_TABS,
  NIST_ACTION_STATUSES,
  NIST_ACTION_STATUS_COLORS,
  NIST_ACTION_STATUS_COLORS_HEX,
  NIST_ACTION_STATUS_COLOR_9_HEX,
  NIST_ACTION_TYPES,
  NIST_COLOR_MAP,
  PRODUCT_TYPES,
  VERTICAL_TABS,
  RISK_PRODUCT_TYPES
};
