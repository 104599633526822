import { Box, Typography } from '@mui/material';
import { Link, useRouteError } from 'react-router-dom';
import scrybLogo from 'assets/img/scryb_logo_lg_blue.png';

const ErrorRouteElement = () => {
  const error = useRouteError();
  console.error(error);

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '70vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <img
        src={scrybLogo}
        alt="scryb logo"
        style={{ width: '30rem', margin: '1rem', marginBottom: '3rem' }}
      />
      <Typography color="grey.600" variant="h5" gutterBottom>
        Sorry, an unexpected error has occurred:
      </Typography>
      <Typography color="error" variant="body1" gutterBottom>
        {error.statusText || error.message}
      </Typography>
      <Link to={'/'}>Go Home</Link>
    </Box>
  );
};

export default ErrorRouteElement;
