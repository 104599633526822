import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import scrybLogo from 'assets/img/scryb_logo_sm_blue.png';
import { useAuth } from 'contexts/auth-context';
import { APP_HEADER_HEIGHT } from 'lib/constants/app';
import { NavLink } from 'react-router-dom';
import SignOutContainer from './sign-out-container';

// 66a3e92218ec387a5b73a56f
const Header = () => {
  const { user, signOut } = useAuth();

  // signout btn and avatar in navbar
  const signOutCntainer = user?.email && user?.token && (
    <SignOutContainer srcImg={user?.picture} onClick={() => signOut(false)}></SignOutContainer>
  );

  return (
    <AppBar position="sticky" sx={{ height: APP_HEADER_HEIGHT }}>
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: '0.35rem',
          justifyContent: 'space-between'
        }}
      >
        <NavLink to="/" style={{ textDecoration: 'none' }}>
          <img src={scrybLogo} alt="scryb logo" style={{ width: '8rem', marginRight: '0.5rem' }} />
        </NavLink>
        {signOutCntainer}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
