import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const NotFoundIndex = () => {
  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '70vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Typography variant="h4" gutterBottom>
        404 Page Not Found
      </Typography>
      <Link to={'/'}>Go Home</Link>
    </Box>
  );
};

export default NotFoundIndex;
