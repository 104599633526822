import { Box } from '@mui/material';
import Header from 'components/header/header';
import { Navigate, Route, Routes, useLocation, useSearchParams } from 'react-router-dom';
import './App.css';

import { GoogleOAuthProvider } from '@react-oauth/google';
import Home from 'pages/home';
import NotFoundIndex from 'pages/not-found-index';
import SignIn from 'pages/sign-in'; // Import additional pages as needed

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import axios from 'axios';
import ComplianceRiskIndex from 'components/compliance/risk/compliance-risk-index';
import { useAuth } from 'contexts/auth-context';
import { RISK_PRODUCT_TYPES } from 'lib/constants/compliance';
import { STALE_TIME } from 'lib/constants/query';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import ComplianceIndex from 'pages/compliance-index';
import ConditionalRoutes from 'pages/conditional-route';
import ErrorRouteElement from 'pages/error-route-element';
import ProtectedRoute from 'pages/protected-route';
import { lazy, useEffect, useRef } from 'react';

const ComplianceAIProduct = lazy(() => import('components/ai-product/compliance-ai-product'));
const ComplianceAIProducts = lazy(() => import('components/ai-product/compliance-ai-products'));
const ComplianceActions = lazy(() => import('components/compliance/actions/compliance-actions'));
const ComplianceCustomPolicies = lazy(() =>
  import('components/compliance/custom-policies/compliance-custom-policies')
);
const ComplianceCustomPolicy = lazy(() =>
  import('components/compliance/custom-policies/compliance-custom-policy')
);
const DashboardActionTotal = lazy(() =>
  import('components/compliance/dashboard/dashboard-action/dashboard-action-total')
);
const DashboardIndex = lazy(() => import('components/compliance/dashboard/dashboard-index'));

const DashboardProductsType = lazy(() =>
  import('components/compliance/dashboard/dashboard-product/dashboard-products-type')
);
const CompalianceDocuments = lazy(() =>
  import('components/compliance/documents/compliance-documents')
);
const ComplianceCanada = lazy(() =>
  import('components/compliance/dynamic-policies/compliance-canada')
);
const ComplianceEuAct = lazy(() => import('components/compliance/dynamic-policies/compliance-eu'));
const ComplianceUK = lazy(() => import('components/compliance/dynamic-policies/compliance-uk'));
const ComplianceRMF = lazy(() => import('components/compliance/rmf/compliance-rmf'));
const ComplianceUsers = lazy(() => import('components/compliance/users/compliance-users'));
const DashboardStatusIndex = lazy(() =>
  import('components/compliance/dashboard/dashboard-status/dashboard-status-index')
);
const DashboardUsersIndex = lazy(() =>
  import('components/compliance/dashboard/dashboard-user/dashboard-user-index')
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: STALE_TIME
    }
  }
});

function App() {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { user, signOut, refreshToken } = useAuth();
  const msgKeyRef = useRef(null);

  useEffect(() => {
    if (user && user.token) {
      refreshToken().catch((err) => console.error('refresh error:', err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error?.status === 401 || error?.response?.status === 401) {
        if (location.pathname !== '/signin') {
          // TODO figure out how this works with react-query
          return signOut();
        }
      }

      if (msgKeyRef.current) {
        closeSnackbar(msgKeyRef.current);
      }
      error = error.response ? error.response : error;
      const statusText = error.statusText ?? error.status ?? '';
      msgKeyRef.current = enqueueSnackbar(
        `${statusText ? statusText + ':' : ''} ${
          error.data?.message ?? error.message ?? 'Unknown Error'
        }`,
        { variant: 'error' }
      );

      return Promise.reject(error);
    }
  );

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <QueryClientProvider client={queryClient}>
        <Box
          className="App"
          sx={{ display: 'flex', flexDirection: 'column', width: '100%', minHeight: '100lvh' }}
        >
          <Header />
          <Routes>
            <Route
              index
              element={
                <ProtectedRoute>
                  <Home />
                </ProtectedRoute>
              }
              errorElement={<ErrorRouteElement />}
            />
            <Route
              path="/compliance/:orgId"
              element={
                <ProtectedRoute>
                  <ComplianceIndex />
                </ProtectedRoute>
              }
              errorElement={<ErrorRouteElement />}
            >
              <Route path="dashboard" element={<DashboardIndex />}>
                {/* Nested products route under dashboard */}
                <Route path="status-summary" element={<DashboardStatusIndex />} />
                <Route path="product-types" element={<DashboardProductsType />} />
                <Route path="top-actions" element={<DashboardActionTotal />} />
                <Route path="top-users" element={<DashboardUsersIndex />} />

                <Route
                  index
                  element={<Navigate to={{ ...location, pathname: 'status-summary' }} replace />}
                />
              </Route>
              <Route path="users" element={<ComplianceUsers />} />
              <Route path="nist" element={<ComplianceRMF />} />

              <Route
                element={
                  <ConditionalRoutes
                    isAllowed={!searchParams.has('prId')}
                    redirectTo={{ ...location, pathname: `product/${searchParams.get('prId')}` }}
                  />
                }
              >
                <Route path="products" element={<ComplianceAIProducts />} />
              </Route>
              <Route path="product/:productId?" element={<ComplianceAIProduct />} />
              <Route path="actions" element={<ComplianceActions />} />
              <Route path="documents" element={<CompalianceDocuments />} />
              <Route
                element={
                  <ConditionalRoutes
                    isAllowed={RISK_PRODUCT_TYPES.indexOf(searchParams.get('prType')) > -1}
                    redirectTo={{ ...location, pathname: `product/${searchParams.get('prId')}` }}
                  />
                }
              >
                <Route path="risk" element={<ComplianceRiskIndex />} />
              </Route>
              <Route index element={<Navigate to={{ ...location, pathname: 'users' }} replace />} />
              {/* Dynamic Compliance Routes */}
              <Route path="canada" element={<ComplianceCanada />} />
              <Route path="eu" element={<ComplianceEuAct />} />
              <Route path="uk" element={<ComplianceUK />} />
              <Route path="custom-policies" element={<ComplianceCustomPolicies />} />
              <Route
                path="custom-policy/:policyType/:policyId?"
                element={<ComplianceCustomPolicy />}
              />
              {/* Dynamic Compliance Routes END */}
              <Route
                index
                element={<Navigate to={{ ...location, pathname: 'dashboard' }} replace />}
              />
            </Route>
            <Route
              path="/product/:sProductId"
              element={
                <ProtectedRoute>
                  <ComplianceIndex />
                </ProtectedRoute>
              }
              errorElement={<ErrorRouteElement />}
            ></Route>
            <Route path="/signin" element={<SignIn />} errorElement={<ErrorRouteElement />} />
            <Route path="*" element={<NotFoundIndex />} />
            {/* Add more routes as needed */}
          </Routes>
        </Box>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
