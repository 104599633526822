import { httpService } from './http.service.js';

const ENDPOINT = 'app-requirements/';

export const riskService = {
  getAppRequirementsSection,
  getAppRequirementsDataSection
};

async function getAppRequirementsSection(productId, section = 'ha') {
  return await httpService.get(`${ENDPOINT}/product/${productId}/section/${section}`);
}

async function getAppRequirementsDataSection(productId, section = 'ha') {
  return await httpService.get(`${ENDPOINT}/data/${productId}/section/${section}`);
}
