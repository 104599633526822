import { Box, Container, Paper, useMediaQuery, useTheme } from '@mui/material';
import ComplianceTabs from 'components/compliance/compliance-tabs';
import TreeHirarchicalList from 'components/compliance/tree-hirarchical-list';
import { OrgDataProvider } from 'contexts/org-data-context';
import { APP_HEADER_HEIGHT } from 'lib/constants/app';
import { Suspense, useEffect, useMemo } from 'react';
import { Outlet, useParams, useSearchParams } from 'react-router-dom';

const ComplianceIndex = () => {
  const { productId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const theme = useTheme();
  const isGreaterMd = useMediaQuery(theme.breakpoints.up('md'));
  const isGreaterLg = useMediaQuery(theme.breakpoints.up('lg'));
  const isGreaterXl = useMediaQuery(theme.breakpoints.up('xl'));

  const treePxWidth = useMemo(() => {
    if (isGreaterLg) return '350px';
    if (isGreaterMd) return '250px';
    return 0;
  }, [isGreaterLg, isGreaterMd]);

  useEffect(() => {
    // clear prId, prType if it doesn't match product id
    if (productId && searchParams.has('prId') && productId !== searchParams.get('prId')) {
      searchParams.delete('prId');
      searchParams.delete('prType');
      setSearchParams(searchParams);
    }
  }, [productId, searchParams, setSearchParams]);

  return (
    <OrgDataProvider>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        {isGreaterMd && (
          <Paper
            variant="elevation"
            elevation={2}
            square
            sx={{
              width: treePxWidth,
              borderBottom: 0,
              padding: 2,
              paddingBottom: 3,
              position: 'sticky',
              top: APP_HEADER_HEIGHT,
              height: `calc(96lvh - ${APP_HEADER_HEIGHT})`,
              overflowY: 'auto',
              zIndex: 1000
            }}
          >
            <TreeHirarchicalList />
          </Paper>
        )}
        <Container
          sx={{
            paddingTop: '1rem',
            paddingRight: (isGreaterXl ? '5rem' : '1.5rem') + ' !important',
            maxWidth: `calc(100% - ${treePxWidth}) !important`
          }}
        >
          <ComplianceTabs />
          <Suspense>
            <Outlet />
          </Suspense>
        </Container>
      </Box>
    </OrgDataProvider>
  );
};

export default ComplianceIndex;
